@import '../styles/variables.scss';

.ag-theme-material {
    --ag-header-height: 30px;
    --ag-header-foreground-color: $main-color-text;
    // --ag-header-background-color: rgba(0, 2, 67, 1);
    // --ag-header-cell-hover-background-color: rgb(80, 40, 140);
    // --ag-header-cell-moving-background-color: rgb(80, 40, 140);

    // --ag-foreground-color: rgb(126, 46, 132);
    // --ag-background-color: rgb(249, 245, 227);
    // --ag-header-foreground-color: rgb(204, 245, 172);
    // --ag-header-background-color: rgb(209, 64, 129);
    // --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    // --ag-header-column-resize-handle-color: rgb(126, 46, 132);

    --ag-font-size: 15px;
    --ag-font-family: 'WorkSansRegular';
    --ag-material-accent-color: #000000;
    --ag-cell-horizontal-padding: 10px;
}

.ag-header-cell-label {
    justify-content: center;
}

.ag-theme-material .ag-header {
    font-family: 'WorkSansSemiBold';
}

.ag-theme-material .ag-header-group-cell {
    font-weight: normal;
    font-size: 22px;
}

.ag-theme-material .ag-header-cell {
    font-size: 15px;
    text-align: center;
    padding-bottom: 0.7rem;
    text-transform: uppercase;
    color: $main-color-text;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: 0px;
}

.ag-header-cell-label {
    padding-left: 18px;
}

.btn-details {
    .btn:first-child:active {
        border: 0px;
    }
}

.login-screen {
    background-image: url("../assets/images/bg-login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        display: contents;
        .card {
            width: 300px !important;
            box-shadow: rgba(0, 0, 0, 0.27) 7px 6px 0px -2px;
            border-radius: 0;
            background-color: $bg-login-sc
        }
    }
}

.btns-approve-all {
    button {
        font-family: "WorkSansSemiBold";
        background-color: $bg-btn-gray-dark-color;
        border-color: $bg-btn-gray-dark-color;
    }
}

.tab-novedades {
    border-bottom: 0px;
    padding-top: 5rem;
    .nav-item {
        padding-top: 1.5rem;
        padding-left: 10px;
        padding-right: 10px;
        button {
            background-clip: padding-box;
            border-radius: 0;
            border: 1px solid $bg-gray-light-color;
            background-color: white;
            color: $bg-gray-light-color;
            text-transform: uppercase !important;
            text-align: center !important;
            font-family: 'WorkSansSemiBold';
            padding-top: 0.7rem;
            padding-bottom: 0.7rem;
        }
        button:hover, button.nav-link.active {
            background-color: $bg-gray-dark-color;
            color: white;
            border-color: $bg-gray-dark-color;
        }
    }
}

.container-liquidacion, .container-liquidacion-detalle, .container-historial-pago {
    padding-top: 5rem;
    .header-info {
        .content-card {
            background-color: $bg-gray-color;
            padding: 1.3rem;
            border-radius: 9px;
            p {
                color: $main-color-text;
            }
            p.text {
                font-family: "WorkSansSemiBold";
                font-size: 15px;
                margin-bottom: 0.4rem;
            }
            p.value {
                font-family: "WorkSansBold";
                font-size: 20px;
                margin-bottom: 0.4rem;
            }
            p.value-month {
                font-family: "WorkSansBold";
                font-size: 20px;
                margin-bottom: 0;
                padding: 18px 0;
            }
        }
    }
    .content-table {
        margin-top: 1rem;
        .container-grid {
            height: calc(95vh - 360px)
        }
    }
}

.actions-cell {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}