@import '../../styles/variables.scss';

.content-sidebar {
    width: 300px;
    border-right: 1px solid rgba(217, 217, 217, 1);
    .site-title {
        h4 {
            color: $main-color-text
        }
    }
    .container {
        p {
            color: $main-color-text
        }
    }
}

.header-fixed {
    .container-fluid {
        .title {
            color: $main-color-text;
            padding-left: 0.5rem;
        }
    }
}

.nav-item-left-side {
    border-bottom: 2px solid rgba(217, 217, 217, 1);
    button {
        font-family: "WorkSansSemiBold";
    }
    button, button:active, button:focus, button:hover {
        color: $main-color-text !important
    }
}

.nav-left-side {
    .nav-item-selected, .nav-item:hover, .btn-exit button:hover {
        background-color: $bg-gray-color;
    }
}

.nav-left-sel-element {
    li:nth-child(2) {
        background-color: $bg-gray-color;
    }
}

.btn-exit {
    .container-btn {
        border-top: 2px solid $bg-gray-color;
        border-bottom: 2px solid $bg-gray-color;
        button {
            font-family: "WorkSansSemiBold";
            border-radius: 0;
            color: $main-color-text !important;
        }
        button:hover {
            background-color: $bg-gray-color;
        }
    }
}

.content-spinner{
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-notification{
    .toast-header {
        padding: 0px;
        background-color: transparent !important;
        border-bottom: 0px solid transparent;
    }
}
