@import '../styles/variables.scss';

.btns-novedades {
    button.hover, button:hover  {
        background-color: $bg-gray-dark-color;
        color: white;
        border: 0;
    }
    button {
        border-radius: 0;
        border: 1px solid $border-gray-color;
        background-color: white;
        color: $bg-gray-light-color;
    }
}

.login-form {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    margin-bottom: 2rem;
    div, input, small {
        font-family: "WorkSansRegular";
    }
    .btn-submit {
        button {
            background-color: $login-btn-color;
            font-family: "WorkSansSemiBold";
            border: 1px solid $login-btn-color;
        }
        button.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
            background-color: $login-btn-color;
            border-color: $login-btn-color;
        }
    }
}

.btn-actions {
    button {
        // font-size: 1.35rem !important;
        p {
            margin-bottom: 0px;
        }
    }
    button.edit {
        i {
            display: inline-block;
            background-image: url('../assets/images/ModificarPendientes.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
    }
    button.details {
        i {
            display: inline-block;
            background-image: url('../assets/images/liquidation-details.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
    }
    button.download {
        i {
            display: inline-block;
            background-image: url('../assets/images/liquidation-download.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
    }
    button.pay {
        i {
            display: inline-block;
            background-image: url('../assets/images/liquidation-pay.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
    }
    button.novedad {
        i {
            display: inline-block;
            background-image: url('../assets/images/boton-agregar.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
    }
    button.undo {
        i {
            display: inline-block;
            background-image: url('../assets/images/details-undo.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
    }
    .btn.disabled {
        border: 0px
    }
}

.btn-details {
    button {
        font-size: 1.35rem !important;
        i {
            display: inline-block;
            background-image: url('../assets/images/DetallesPendientes.png');
            background-repeat: no-repeat;
            height: 23px;
            width: 23px;
            background-size: 23px 23px;
            content: "";
        }
        p {
            margin-bottom: 0px;
        }
    }
}

.details-cell {
    text-align: center;
}

.actions-cell {
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}