@import '../../styles/variables.scss';

.details-modal {
    .modal-dialog {
            .modal-header {
                border-bottom: 0;
            }
    }
}
.details-table {
    text-align: center;
}
.details-table, .pay-details-table {
    border: transparent;
    thead {
        th {
            font-family: "WorkSansBold";
            color: $main-color-text
        }
    }
    tbody {
        td {
            font-family: "WorkSansRegular";
        }
    }
}
.approve-modal, .reject-modal {
    p {
        font-family: "WorkSansSemiBold";
    }
    div.approve-btn-actions, .btn-submit {
        Button {
            font-family: "WorkSansSemiBold";
            background-color: $btn-primary-color;
            border: 1px solid $btn-primary-color;
        }
        button.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
            background-color: $btn-primary-color;
            border-color: $btn-primary-color;
        }
    }
}
.pay-modal {
    .modal-header {
        position: relative;
        padding-bottom: 0.5rem;
        h4 {
            font-family: "WorkSansSemiBold";
        }
        button.btn-close {
            position: absolute;
            top: 0.8rem;
            right: 0.5rem;
        }
    }
    .modal-body {
        padding-top: 0px;
        margin-top: 0.8rem;
    }
    .card {
        border-width: 0px;
        .card-body {
            padding: 0.5rem;
            background-color: #F4F4F4;
            border-radius: 9px;
            h5 {
                font-family: "WorkSansSemiBold";
                font-size: 15px;
            }
            .content-item-detalles {
                .val-items {
                    font-family: "WorkSansRegular";
                    color: #000000;
                    font-size: 13px;
                    margin-bottom: 0px;
                    line-height: 15px;
                }
            }
        }
        .card-footer {
            background-color: transparent;
            border-top: 0px;
            border-bottom: 1px solid black;
            .total {
                font-family: "WorkSansSemiBold";
                font-size: 18px;
                color: black;
                padding-left: 0px;
            }
            .value {
                font-family: "WorkSansBold";
                font-size: 20px;
                color: black;
                text-align: right;
            }
        }
        .pay-trans {
            h4 {
                font-family: "WorkSansBold";
                font-size: 15px;
                padding-top: 0.5rem;
            }
            .btn-submit {
                Button {
                    font-family: "WorkSansSemiBold";
                    background-color: $btn-primary-color;
                    border: 1px solid $btn-primary-color;
                }
                button.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
                    background-color: $btn-primary-color;
                    border-color: $btn-primary-color;
                }
            }
            .items-btn-radio {
                label {
                    span {
                        font-family: "WorkSansRegular";
                        font-size: 15px;
                    }
                }
            }
            .content-item-payways {
                .abono {
                    .total {
                        margin-bottom: 5px;
                         span {
                            font-family: "WorkSansBold";
                            font-size: 16px;
                        }
                    }
                }
                .desvincular {
                    margin-top: 1rem;
                }
            }
            .line {
                border-bottom: solid 1px #B3B3B5;
                margin-bottom: 0.5rem;
            }
            .note {
                border-radius: 9px;
                background: #F4F4F4;
                p {
                    font-family: "WorkSansRegular";
                    padding: 0.5rem;
                    text-align: center;
                    font-size: 12px;
                    color: #000000;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
}
.pay-details-table > tbody > tr:nth-of-type(odd) > * {
    --bs-table-bg-type: rgba(0, 0, 0, 0);
}
.pay-details-table {
    margin-bottom: 0px;
    tbody {
        tr > td {
            padding-left: 0px;
            padding-right: 0px;
            background-color: #F4F4F4;
            p {
                margin-bottom: 0px;
                font-size: 13px;
            }
        }
    }
}