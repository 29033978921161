* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

html, body, #root, .App {
  height: 100%;
}

@font-face {
  font-family: "WorkSansRegular";
  src: local("WorkSansRegular"),
    url("./fonts/WorkSans/static/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSansBold";
  src: local("WorkSansBold"),
    url("./fonts/WorkSans/static/WorkSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSansLight";
  src: local("WorkSansLight"),
   url("./fonts/WorkSans/static/WorkSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSansMedium";
  src: local("WorkSansMedium"),
   url("./fonts/WorkSans/static/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "WorkSansSemiBold";
  src: local("WorkSansSemiBold"),
   url("./fonts/WorkSans/static/WorkSans-SemiBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'WorkSansRegular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
